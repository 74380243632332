window.__CONFIGURATION__ = {
  environment: window.getEnvironmentFromURL(),
  websocket: {
    ws: {
      uri: window.__URI__()
    }
  },
  repository: {
    reconnectionTimeout: 5000,
    reconnectionLimit: 10
  },
  sentry: {
    dsn: "https://6aa32d87fe964b2486d6f06651367fc8@o395700.ingest.sentry.io/4505556439728128",
    traceTargets: [/^https:\/\/(?:dev\.|staging\.)?(?:client\.|assistant\.)?aire\.link/]
  }
}

window.__INITIAL_STATE__ = {
  app: {
    isMicrophoneRecommended: true,
    isCameraRecommended: false,
    isLocationRecommended: true,
    isHmtDevice: false,
    roles: {
      assistant: [
        'muteParticipants',
        'exchangeRole',
        'showInfo'
      ]
    },
    idealConstraints: {
      microphone: {
        noiseSuppression: true,
        echoCancellation: true  
      }
    }
  }
}
